.select-container {
    display: flex;
    flex-direction: column;
}

.select-container select{
    margin-top: 5px;
    background-color: var(--color-whitesmoke);
    border-color: var(--color-darkgray);
    border-radius: 8px;
    border-width: 4px;
    width: inherit;
    border-style: double;
    font-size: inherit;
    line-height: 1em;
}

.select-container select:focus {
    outline-style: dashed;
    outline-width: 2px;
    outline-color: var(--color-darkgray);
}

.select-container.transparent select{
    border-color: transparent;
    color: inherit;
}

.select-container.green select{
    border-color: var(--color-green);
}

.select-container.red select{
    border-color: var(--color-red);
}

.select-container.darkgray select{
    border-color: var(--color-darkgray);
}

.select-container.lightgray select{
    border-color: var(--color-lightgray);
}

[data-theme="dark"] 
    .select-container {
        color: white;
    }
    [data-theme="dark"] .select-container:not(.red,.green,.transparent,.lightgray) select{
        background-color: var(--color-whitesmoke);
        border-color: var(--color-lightgray);
        color: var(--color-darkgray);
    }

    [data-theme="dark"]   .select-container select:focus {
        outline-color: var(--color-lightgray);
    }

    [data-theme="dark"]    .select-container.darkgray select{
        border-color: var(--color-lightgray);
    }