.station-unit {
    display: block;
    position: relative;
}

.station-unit .filter-wrapper {
    margin-bottom: 15px;
    display: flex;
    flex-flow: row-reverse;
    width: 100%;
    flex-wrap: wrap;
}

.station-unit .refreshed-time {
    padding: 10px;
    color: var(--color-lightgray);
    font-size: medium;
    text-align: right;
    flex-grow: 1;
    flex-shrink: 1;
    margin: auto;
}

.station-unit .refreshed-time > * {
    margin: 5px 0;
}

.station-unit .chart-filters {
    flex-grow: 2;
    flex-shrink: 1;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
}

.station-unit .refreshed-time .lock-unlock {
    margin-left: 10px;
    display: inline-block;
}

.station-unit .chart-filters .filter {
    flex-grow: 0;
    padding: 10px;
    flex-shrink: 1;
    margin: auto 0;
}

.station-unit .chart {
    width: 600px;
}