html,body,#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  --color-green: #1BD603;
  --color-lightgray: #909090;
  --color-darkgray: #303030;
  --color-whitesmoke:#E0E0E0;
  --color-red: #c80024;
}

input {
  border-style: solid;
}

::selection {
  background: var(--color-green); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: var(--color-green); /* Gecko Browsers */
}
