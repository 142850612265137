.left-nav-icons {
  display: flex;
  flex-flow: column;
  text-align: center;
  height: fit-content;
  padding-top: 30px;
  gap: 30px;
  padding-left: calc((var(--logo-width) - 60px)/2);
  padding-right: calc((var(--logo-width) - 60px)/2);
}

.left-nav-icon {
  font-size: 30px;
  background-color: var(--color-lightgray);
  border-radius: 100%;
  width: 60px;
  height: 60px;
  line-height: 2;
}

.left-nav-icon a {
  color: inherit;
}

.left-nav-icon img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.left-nav-icon a:active {
  color: var(--color-divider);
}