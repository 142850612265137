.station-units-wrap {
    display: flex;
    min-height: 52px;
    line-height: calc(2em + 20px);
}

.station-unit {
    flex: 1;
    text-align: center;
}

/*.station-unit button {
    margin: auto;
}*/

.station-unit button.large {
    width: 60px;
    height: 60px;
    transition: all 1s;
}

.consumer-kwh {
    display: inline;
}

.error {
    color: var(--color-red)
}