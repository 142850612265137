.tooltip {
    align-items: center;
    position: relative;
    cursor: pointer;
}

.tooltip:hover .tooltiptext {
    opacity: 1;
    transform: translate(-50%, 0);
}

.tooltip .tooltiptext {
    position: absolute;
    left: 50%;
    bottom: 100%;
    opacity: 0;
    margin-bottom: 1em;
    padding: 1em;
    background-color: #000;
    font-size: .6em;
    line-height: 1.6;
    text-align: left;
    white-space: nowrap;
    transform: translate(-50%, 1em);
    transition: all .15s ease-in-out;
}

.tooltip .tooltiptext::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border: .5em solid transparent;
    border-top-color: #000;
    transform: translate(-50%, 0);
}