.search-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.search-container.inline {
    display: inline-block;
}

.search-container.inline span {
    margin-right: 10px;
}

.search-container input {
    margin-top: 5px;
    background-color: var(--color-whitesmoke);
    border-color: var(--color-lightgray);
    border-radius: 8px;
    border-width: 4px;
    width: inherit;
    border-style: double;
    font-size: inherit;
    line-height: 1em;
    box-sizing: border-box;
}

.search-container .icon {
    display: inline;
}

.search-container input:focus {
    outline-style: dashed;
    outline-width: 2px;
    outline-color: var(--color-darkgray);
}

.search-container.transparent input {
    border-color: transparent;
    color: inherit;
}

.search-container.green input {
    border-color: var(--color-green);
}

.search-container.red input {
    border-color: var(--color-red);
}

.search-container.darkgray input {
    border-color: var(--color-darkgray);
}

.search-container.lightgray input {
    border-color: var(--color-lightgray);
}

.search-container .autocomplete_holder {
    position: relative;
    width: 100%;
    float: right;
}

.search-container .autocomplete {
    z-index: 10000;
    display: none;
    position: absolute;
    width: 100%;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
    background: var(--color-whitesmoke);
    color: var(--color-darkgray);
    border-color: var(--color-lightgray);
    border-style: double;
    border-width: 1px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: auto;
}

.search-container .autocomplete li {
    display: block;
    padding: 5px;
    font-size: inherit;
    line-height: 1em;
    font-family: Arial, Helvetica, sans-serif;
}

.search-container .autocomplete li.active {
    background-color: var(--color-lightgray);
}

[data-theme="dark"] .search-container {
    color: white;
}

[data-theme="dark"] .search-container:not(.red, .green, .transparent, .lightgray) input {
    background-color: var(--color-whitesmoke);
    border-color: var(--color-lightgray);
    color: var(--color-darkgray);
}

[data-theme="dark"] .search-container input:focus {
    outline-color: var(--color-lightgray);
}

[data-theme="dark"] .search-container.darkgray input {
    border-color: var(--color-lightgray);
}