.button {
    padding: 0.5em 0.75em;
    width: fit-content;
    background-color: var(--color-lightgray);
    color: var(--color-darkgray);
    border-radius: 8px;
    border-width: 0;
    font-size: inherit;
    cursor: pointer;
}

/*
.button.large {
    font-size: x-large;
    font-weight: bold;
    line-height: 1.5em;
}*/

.button.small {
    padding: 5px 8px;
    font-size: x-small;
    line-height: 1em;
}

.button.lightgray {
    background-color: var(--color-lightgray);
}

.button.darkgray {
    background-color: var(--color-darkgray);
    color: white;
}

.button.green {
    background-color: var(--color-green);
}

.button.white {
    background-color: white;
}

.button.transparent {
    background-color: transparent;
}

.button.red {
    background-color: var(--color-red);
}

[data-theme="dark"] .button {
    color: white;
}

[data-theme="dark"] .button.white {
    color: var(--color-darkgray)
}

button.h2 {
    font-size: 1.5em;
}