.app {
  width: 100%;
  height: 100%;
  --height-top: 100px;
  --width-left: 200px;
  --logo-width: 200px;
  --color-divider: var(--color-green);
  background-color: white;
  color: var(--color-darkgray);
  --divider-width: 4px;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  position: relative;
}

.left,
.right {
  flex-grow: 1;
}

.left {
  width: var(--logo-width);
  height: 100%;
  top: 0;
  left: 0;
}

.logo {
  height: var(--height-top);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('./rollin-logo.png');
}

.horizontal-divider {
  width: 100%;
  position: absolute;
  border-top: var(--divider-width) solid var(--color-divider);
  border-bottom: 0;
  margin: 0;
}

.left-navbar {
  width: var(--logo-width);
  height: calc(100% - var(--height-top) - var(--divider-width));
  margin-top: var(--divider-width);
}

.right {
  top: 0;
  left: calc(var(--logo-width));
  width: calc(100% - var(--logo-width));
  border-left: var(--divider-width) solid var(--color-divider);
  height: 100%;
}


.top-navbar {
  width: 100%;
  height: var(--height-top);
}

.main {
  height: calc(100% - var(--height-top) - var(--divider-width));
  margin-top: var(--divider-width);
  width: 100%;
  overflow: auto;
}

.main > *:not(.progress-container) {
  margin: 15px 30px;
}

.main > *:not(.progress-container):first-of-type {
  margin-top: 30px;
}

/*progress logo*/
.progress-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-lightgray);
  opacity: .7;
  z-index: 1;
}

.progress-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.progress {
  width: 100px;
  height: 100px;
  pointer-events: none;
  top: 40%;
  left: 40%;
  position: absolute;
  z-index: 2;
}

.hidden {
  display: none;
}

.clickable {
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .progress {
    animation: App-logo-spin infinite 10s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

[data-theme="dark"] .app {
  --color-divider: var(--color-green);
  background-color: var(--color-darkgray);
  color: white;
}

[data-theme="dark"] .logo {
  background-image: url('./rollin-logo-inverse.png');
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-whitesmoke);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-lightgray);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-green);
}