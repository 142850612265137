.input-container {
    display: flex;
    flex-direction: column;
}

.input-container input{
    margin-top: 5px;
    background-color: var(--color-whitesmoke);
    border-color: var(--color-darkgray);
    border-radius: 8px;
    border-width: 4px;
    border-style: double;
    font-size: inherit;
    line-height: 1em;
    box-sizing: border-box;
    width: inherit;
}

.input-container .input {
    display: inline-block;
    position: relative;
    width: 100%;
}

.input-container .error-message {
    visibility: hidden;
    width: 80%;
    background-color: var(--color-red);
    color: white;
    text-align: center;
    border-radius: 1px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 10%;
    opacity: 0;
    transition: opacity 0.7s;
}

.input-container .error-message:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--color-red) transparent transparent transparent;
}

.input-container:hover .error-message {
    visibility: visible;
    opacity: 1;
}

.input-container input:focus {
    outline-style: dashed;
    outline-width: 2px;
    outline-color: var(--color-darkgray);
}

.input-container.transparent input{
    border-color: transparent;
    color: inherit;
}

.input-container.green input{
    border-color: var(--color-green);
}

.input-container.red input{
    border-color: var(--color-red);
}

.input-container.darkgray input{
    border-color: var(--color-darkgray);
}

.input-container.lightgray input{
    border-color: var(--color-lightgray);
}

.checkbox-container > :first-child, .input-container > :first-child {
    margin-right: 15px;
}

.checkbox-container {
    direction: ltr;
}
.checkbox-container input {
    color: white;
}
.checkbox-container .input {
    display: inline-block;
}
.checkbox-container input[type="checkbox" i] {
    accent-color: var(--color-green);
    color: white;
}

[data-theme="dark"] 
    .input-container {
        color: white;
    }
    [data-theme="dark"] .input-container:not(.red,.green,.transparent,.lightgray) input{
        background-color: var(--color-whitesmoke);
        border-color: var(--color-lightgray);
        color: var(--color-darkgray);
    }

    [data-theme="dark"]   .input-container input:focus {
        outline-color: var(--color-lightgray);
    }

    [data-theme="dark"]    .input-container.darkgray input{
        border-color: var(--color-lightgray);
    }