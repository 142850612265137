.users-list .header {
    cursor: pointer;
    margin-left: 15px;
}
.users-list .header div{
    display: inline-block;
    margin-right: 15px;
}
.users-list .user:nth-child(odd) {
    background-color: var(--color-lightgray);
}

.users-list .user-info {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    margin-left: 30px;
}
.users-list .expanded .user-info {
    max-height: 500px;
    transition: max-height 0.3s ease-in;
}